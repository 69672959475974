.section-d {
    position: relative;
    width: 1728px;
    height: 766px;
    margin: 0px 0 0 0;
    z-index: 50;
    left: 170px;
  }
  
  .box-a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 30px;
    position: absolute;
    width: 430px;
    height: 530px;
    top: 0;
    left: 600px;
    padding: 40px 45px 40px 45px;
    background: #ffffff;
    border: 2px solid #48afe3;
    z-index: 29;
    border-radius: 15px;
  }
  .text-29 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 28px;
    color: #48afe3;
    font-family: Roboto, var(--default-font-family);
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 30;
  }
  .box-b {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;
    position: relative;
    width: 340px;
    z-index: 31;
  }
  .wrapper-9 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;
    position: relative;
    width: 340px;
    z-index: 32;
  }
  .text-2a {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 19px;
    color: #252525;
    font-family: Roboto, var(--default-font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
    white-space: nowrap;
    z-index: 33;
  }
  .section-e {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 340px;
    height: 100px;
    padding: 20px 15px 20px 15px;
    border: 2px solid #eae9e9;
    z-index: 34;
    overflow: hidden;
    border-radius: 10px;
  }
  .text-2b {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 136px;
    height: 10px;
    color: rgba(176, 176, 176, 0.4);
    font-family: Roboto, var(--default-font-family);
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
    text-align: center;
    white-space: nowrap;
    z-index: 35;
  }
  .section-f {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;
    position: relative;
    width: 340px;
    z-index: 36;
  }
  .text-2c {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 19px;
    color: #252525;
    font-family: Roboto, var(--default-font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
    white-space: nowrap;
    z-index: 37;
  }
  .group-13 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 340px;
    /* height: 50px; */
    padding: 20px 15px 20px 15px;
    border: 2px solid #eae9e9;
    z-index: 38;
    overflow: hidden;
    border-radius: 10px;
  }
  .text-2d {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 67px;
    height: 10px;
    color: rgba(176, 176, 176, 0.4);
    font-family: Roboto, var(--default-font-family);
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
    text-align: center;
    white-space: nowrap;
    z-index: 39;
  }
  .group-14 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;
    position: relative;
    width: 340px;
    z-index: 40;
  }
  .text-2e {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 19px;
    color: #252525;
    font-family: Roboto, var(--default-font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
    white-space: nowrap;
    z-index: 41;
  }
  .wrapper-a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 340px;
    /* height: 50px; */
    padding: 20px 15px 20px 15px;
    border: 2px solid #eae9e9;
    z-index: 42;
    overflow: hidden;
    border-radius: 10px;
  }
  .text-2f {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 112px;
    height: 10px;
    color: rgba(176, 176, 176, 0.4);
    font-family: Roboto, var(--default-font-family);
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
    text-align: center;
    white-space: nowrap;
    z-index: 43;
  }
  .box-c {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;
    position: relative;
    width: 340px;
    z-index: 44;
  }
  .text-30 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 19px;
    color: #252525;
    font-family: Roboto, var(--default-font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
    white-space: nowrap;
    z-index: 45;
  }
  .box-d {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 340px;
    padding: 20px 15px 20px 15px;
    border: 2px solid #eae9e9;
    z-index: 46;
    overflow: hidden;
    border-radius: 10px;
  }
  .text-31 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 113px;
    height: 10px;
    color: rgba(176, 176, 176, 0.4);
    font-family: Roboto, var(--default-font-family);
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
    text-align: center;
    white-space: nowrap;
    z-index: 47;
  }
  .section-10 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    /* width: 50px; */
    padding: 15px 24px 15px 24px;
    background: #48afe3;
    z-index: 48;
    border-radius: 4px;
    top: 25px;
  }
  .text-32 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 14px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    white-space: nowrap;
    z-index: 49;
  }
  .wrapper-b {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 20px;
    position: absolute;
    width: 1728px;
    height: 613px;
    bottom: 0;
    left: 50%;
    padding: 20px 150px 20px 150px;
    background: #48afe3;
    transform: translate(-50%, 0);
    z-index: 23;
    overflow: hidden;
  }
  .section-11 {
    flex-shrink: 0;
    position: relative;
    width: 1220px;
    height: 0.9px;
    background: #ffffff;
    opacity: 0.6000000238418579;
    z-index: 24;
    right: 250px;
  }
  .group-15 {
    flex-shrink: 0;
    position: relative;
    width: 284.266px;
    height: 92.926px;
    z-index: 25;
    right: 250px;
  }
  .img-a {
    position: absolute;
    width: 24.64%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0 px 0 100px;
    background: url(../assets/images/white_logo.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 26;
  }
  .group-16 {
    position: absolute;
    width: 71.5%;
    height: 48.07%;
    top: 25.69%;
    left: 28.5%;
    z-index: 28;
  }
  .img-b {
    position: relative;
    width: 203.25px;
    height: 20.708px;
    margin: 0 0 0 0;
    background: url(../assets/images/white_name.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 27;
  }
  .img-c {
    position: relative;
    width: 118.354px;
    height: 13.792px;
    margin: 10.167px 0 0 0;
    background: url(../assets/images/white_logo2.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 28;
  }
  .section-12 {
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 60px;
  position: absolute;
  width: 414px;
  height: 200px;
  top: 217px;
  left: 50px;
  z-index: 50;
  }
  .text-33 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 414px;
    height: 84px;
    color: #ffffff;
    font-family: Roboto, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 42.188px;
    text-align: left;
    z-index: 51;
  }
  .wrapper-c {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 20px;
    position: relative;
    width: 245px;
    z-index: 52;
  }
  .img-d {
    flex-shrink: 0;
    position: relative;
    width: 34px;
    height: 34px;
    background: url(../assets/images/call.png)
      no-repeat center;
    background-size: cover;
    z-index: 53;
    overflow: hidden;
  }
  .text-34 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 171px;
    height: 37px;
    color: #ffffff;
    font-family: Roboto, var(--default-font-family);
    font-size: 20px;
    font-weight: 500;
    line-height: 37px;
    text-align: left;
    z-index: 54;
  }
  .wrapper-d {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 20px;
    position: relative;
    width: 318px;
    z-index: 55;
  }
  .img-e {
    flex-shrink: 0;
    position: relative;
    width: 38px;
    height: 38px;
    background: url(../assets/images/email.png)
      no-repeat center;
    background-size: cover;
    z-index: 56;
    overflow: hidden;
  }
  .text-35 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 14px;
    color: #ffffff;
    font-family: Roboto, var(--default-font-family);
    font-size: 20px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    white-space: nowrap;
    z-index: 57;
  }


  
.contact-text {
  color: #48AEC2;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
}

.form-group label {
  display: block;
  color: #48AEC2;
  margin-bottom: 6px;
}

.form-control {
  background-color: #f8f9fa; /* Bootstrap background-color for form inputs */
  border: 1px solid #ced4da; /* Bootstrap border-color for form inputs */
  border-radius: .25rem; /* Bootstrap border-radius for form inputs */
  width:400px;
    height: 60px;
    color: black;
    font-family: Roboto, var(--default-font-family);
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
    text-align: left;
    white-space: nowrap;
}

.btn-primary {
  background-color: #48AEC2;
  border-color: #48AEC2;
  margin-top: 20px;
}
