.group-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 20px;
    position: relative;
    /* width: 1028px; */
    margin: 1.5px 0 0 0;
    padding: 40px 20px 30px 20px;
    background: #f8f8f8;
  }
  .text-a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 180px;
    height: 22px;
    color: #252525;
    font-family: DM Sans, var(--default-font-family);
    font-size: 32px;
    font-weight: 900;
    line-height: 22px;
    text-align: center;
    white-space: nowrap;
    z-index: 1;
  }
  .group-5 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 1203.8px;
    z-index: 2;
  }
  .wrapper-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 262px;
    z-index: 3;
  }
  .box-4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 220px;
    z-index: 4;
  }
  .img-2 {
    flex-shrink: 0;
    position: relative;
    width: 100px;
    height: 86px;
    background: url(../assets/images/IMS/dcea556c-b7da-43b0-90c3-7b23eff82dce.png)
      no-repeat center;
    background-size: cover;
    z-index: 5;
    overflow: hidden;
  }
  .img-3 {
    flex-shrink: 0;
    position: relative;
    width: 110px;
    height: 78px;
    background: url(../assets/images/IMS/savethechildren.png)
      no-repeat center;
    background-size: contain;
    z-index: 6;
    overflow: hidden;
  }
  .text-b {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 262px;
    height: 15px;
    color: #b3b3b3;
    font-family: Lexend, var(--default-font-family);
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    white-space: nowrap;
    z-index: 7;
  }
  .box-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 230px;
    z-index: 9;
  }
  .section-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 230px;
    z-index: 10;
  }
  .img-4 {
    flex-shrink: 0;
    position: relative;
    width: 110px;
    height: 78px;
    background: url(../assets/images/HomeAppliances/distar.png)
      no-repeat center;
    background-size: contain;
    z-index: 11;
    overflow: hidden;
  }
  .pic-6 {
    flex-shrink: 0;
    position: relative;
    width: 110px;
    height: 78px;
    background: url(../assets/images/HomeAppliances/kimatsu.png)
      no-repeat center;
    background-size: contain;
    z-index: 12;
    overflow: hidden;
  }
  .section-5 {
    flex-shrink: 0;
    position: relative;
    width: 0.9px;
    height: 100px;
    background: #c9c9c9;
    opacity: 0.6000000238418579;
    z-index: 14;
  }
  .section-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 470px;
    z-index: 15;
  }
  .wrapper-6 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 470px;
    z-index: 16;
  }
  .pic-7 {
    flex-shrink: 0;
    position: relative;
    width: 110px;
    height: 78px;
    background: url(../assets/images//Others/Ghangri.png)
      no-repeat center;
    background-size: contain;
    z-index: 17;
    overflow: hidden;
  }
  .img-5 {
    flex-shrink: 0;
    position: relative;
    width: 110px;
    height: 78px;
    background: url(../assets/images/Others/cooperative.png)
      no-repeat center;
    background-size: contain;
    z-index: 18;
    overflow: hidden;
  }
  .pic-8 {
    flex-shrink: 0;
    position: relative;
    width: 110px;
    height: 78px;
    background: url(../assets/images/Others/o2.png)
      no-repeat center;
    background-size: contain;
    z-index: 19;
    overflow: hidden;
  }
  .pic-9 {
    flex-shrink: 0;
    position: relative;
    width: 110px;
    height: 78px;
    background: url(../assets/images/Others/MS.png)
      no-repeat center;
    background-size: contain;
    z-index: 20;
    overflow: hidden;
  }

  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  