.section-c {
    position: relative;
    width: 100vw;
    height: 540px;
    /* margin: 47px 0 0 279px; */
    z-index: 74;
    left: 250px;
  }
  .group-10 {
    position: absolute;
    width: 1020px;
    height: 350px;
  top: 100px;
    left: 76px;
    background: #48afe3;
    opacity: 0.10000000149011612;
    z-index: 59;
    border-radius: 10px;
  }
  .group-11 {
    position: absolute;
    width: 355px;
    height: 268px;
    top: 81px;
    left: 25px;
    background: #f9f9f9;
    z-index: 74;
    border-radius: 5px;
  }
  .img-7 {
    position: relative;
    width: 101.001px;
    height: 17.216px;
    margin: 40px 0 0 32px;
    background: url(../assets/images/star1.png)
      no-repeat center;
    background-size: cover;
    z-index: 78;
  }
  .text-20 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 291px;
    height: 96px;
    margin: 18.784px 0 0 32px;
    color: #818181;
    font-family: Inter, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    z-index: 77;
  }
  .text-21 {
    display: block;
    position: relative;
    height: 17px;
    margin: 18px 0 0 32px;
    color: #313131;
    font-family: Inter, var(--default-font-family);
    font-size: 14px;
    font-weight: 600;
    line-height: 16.943px;
    text-align: left;
    white-space: nowrap;
    z-index: 75;
  }
  .text-22 {
    display: block;
    position: relative;
    height: 12px;
    margin: 9px 0 0 32px;
    color: #959595;
    font-family: Inter, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    white-space: nowrap;
    z-index: 76;
  }
  .box-9 {
    position: absolute;
    width: 380px;
    height: 306px;
    top: 81px;
    left: 396px;
    background: #f9f9f9;
    z-index: 68;
    border-radius: 5px;
    box-shadow: 0 45px 80px 0 rgba(0, 0, 0, 0.04);
  }
  .pic-b {
    position: relative;
    width: 101.001px;
    height: 17.216px;
    margin: 40px 0 0 32px;
    background: url(../assets/images/star1.png)
      no-repeat center;
    background-size: cover;
    z-index: 72;
  }
  .text-23 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 320px;
    height: 128px;
    margin: 18.784px 0 0 32px;
    color: #818181;
    font-family: Inter, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    z-index: 71;
  }
  .text-24 {
    display: block;
    position: relative;
    height: 17px;
    margin: 22px 0 0 32px;
    color: #313131;
    font-family: Inter, var(--default-font-family);
    font-size: 14px;
    font-weight: 600;
    line-height: 16.943px;
    text-align: left;
    white-space: nowrap;
    z-index: 69;
  }
  .text-25 {
    display: block;
    position: relative;
    height: 12px;
    margin: 9px 0 0 32px;
    color: #959595;
    font-family: Inter, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    white-space: nowrap;
    z-index: 70;
  }
  .group-12 {
    position: absolute;
    width: 355px;
    height: 268px;
    top: 81px;
    left: 816px;
    background: #f9f9f9;
    z-index: 62;
    border-radius: 5px;
  }
  .img-8 {
    position: relative;
    width: 101.001px;
    height: 17.216px;
    margin: 40px 0 0 32px;
    background: url(../assets/images/star1.png)
      no-repeat center;
    background-size: cover;
    z-index: 66;
  }
  .text-26 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 291px;
    height: 96px;
    margin: 18.784px 0 0 32px;
    color: #818181;
    font-family: Inter, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    z-index: 65;
  }
  .text-27 {
    display: block;
    position: relative;
    height: 17px;
    margin: 18px 0 0 33px;
    color: #313131;
    font-family: Inter, var(--default-font-family);
    font-size: 14px;
    font-weight: 600;
    line-height: 16.943px;
    text-align: left;
    white-space: nowrap;
    z-index: 63;
  }
  .text-28 {
    display: block;
    position: relative;
    height: 12px;
    margin: 9px 0 0 33px;
    color: #959595;
    font-family: Inter, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    white-space: nowrap;
    z-index: 64;
  }
  .slider {
    position: absolute;
    width: 120px;
    height: 66px;
    top: 448px;
    left: 526px;
    background: url(../assets/images/button_slider.png)
      no-repeat center;
    background-size: cover;
    z-index: 60;
  }
  
  
  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }