
.App {
  position: relative;
  /* max-width: 1920px; */
  max-width: 100vw; /* 1 */
  margin: auto;
  background: #ffffff;
  overflow: hidden;
  
}
.pic-2 {
  position: absolute;
width: 100vw;
height: 600px;
overflow: visible;
left: 550px;
  background: url(./assets/images/faded_pic.png)
    no-repeat center;
  background-size: cover;
  transform: translate(-50%, 0);
  z-index: 120;
}


